.ng-select.t-select .ng-select-container {
  @apply border-grey-40 text-grey-70 text-sm;
}

.ng-select.t-select.has-error .ng-select-container {
  @apply border-amber-70;
}

.ng-select.t-select.has-error.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  @apply focus:border-red-80 focus:ring-red-30 border-amber-70 focus:ring focus:ring-opacity-50;
}

.ng-select.t-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  @apply bg-grey-20 text-grey-100 flex flex-row-reverse text-xs font-semibold;
}

.ng-select.t-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  @apply pl-2;
}

.ng-select.t-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  @apply border-0;
}

.ng-select.t-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  @apply bg-grey-20;
}

.ng-select.t-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  @apply border-grey-70 ring-grey-50 ring ring-opacity-50;
}

.ng-select.t-select.ng-select-single .ng-select-container {
  height: 48px;
}

.ng-select.t-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 10px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  @apply text-sm;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  @apply bg-grey-10;
}

.ng-select.t-select .ng-clear-wrapper {
  width: 18px;
  height: 18px;
  text-align: center;
}
